import React, { useState, useEffect } from "react";
import ClienteHttp from "../../servicio/ClienteHttp";

const evento = new EventSource(
    window.location.protocol + "//horaoficial.ibmetro.gob.bo:" + (window.location.protocol.startsWith("https") ? '9191' : '9292') + "/v1/tiempo"
);
var intervalo = null;
export default function HoraOficial() {
    const [tiempo, setTiempo] = useState({
        anio: 0,
        mes: 0,
        dia: 0,
        hora: 0,
        segundo: 0,
        minuto: 0,
        iniciado: 0,
        nano: 0,
    });
    const [visual, setVisual] = useState({
        anio: 0,
        mes: 0,
        dia: 0,
        hora: 0,
        segundo: 0,
        minuto: 0,
        iniciado: 0,
        nano: 0,
    });
    const [error, setError] = useState(null);
    const formatoDigito = (dato) => {
        return dato >= 10 ? dato : "0" + dato;
    };
    const obtenerTiempo = async () => {
        try {
            await ClienteHttp.get(`hora`)
                .then((response) => {
                    setTiempo(response.data);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        } catch (error) {
            console.log("error", error);
        }
    };
    const obtenerHora = (base) => {
        return (
            formatoDigito(base.hora) +
            ":" +
            formatoDigito(base.minuto) +
            ":" +
            formatoDigito(base.segundo)
        );
    };
    const obtenerLiteral = (base) => {
        const nombres_dia = new Array(
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        );
        const nombres_mes = new Array(
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre"
        );
        return (
            base.dia + " de " + nombres_mes[base.mes - 1] + " del " + base.anio
        );
    };
    const sumarSegundo = () => {
        setVisual((tiempo) => {
            let nuevo = Object.assign({}, tiempo);
            if (nuevo.anio > 0) {
                let nuevoAnio,
                    nuevoMes,
                    nuevoDia,
                    nuevoHora,
                    nuevoMinuto,
                    nuevoSegundo;
                nuevoSegundo = nuevo.segundo + 1;
                if (nuevoSegundo == 60) {
                    nuevo.segundo = 0;
                    nuevoMinuto = nuevo.minuto + 1;
                    if (nuevoMinuto == 60) {
                        nuevo.minuto = 0;
                        nuevoHora = nuevo.hora + 1;
                        if (nuevoHora == 24) {
                            nuevo.hora = 0;
                            nuevoDia = nuevo.dia + 1;
                            if (nuevoDia == 32) {
                                nuevo.dia = 1;
                                nuevoMes = nuevo.mes + 1;
                                if (nuevoMes == 13) {
                                    nuevo.mes = 1;
                                    nuevo.anio = nuevo.anio + 1;
                                } else {
                                    nuevo.mes = nuevoMes;
                                }
                            } else {
                                nuevo.dia = nuevoDia;
                            }
                        } else {
                            nuevo.hora = nuevoHora;
                        }
                    } else {
                        nuevo.minuto = nuevoMinuto;
                    }
                } else {
                    nuevo.segundo = nuevoSegundo;
                }
            }
            return nuevo;
        });
    };
    useEffect(() => {
        obtenerTiempo();
        evento.onmessage = (ev) => {
            setTiempo(JSON.parse(ev.data));
        };
        return () => {
            evento.close();
        };
    }, []);
    useEffect(() => {
        setVisual(tiempo);
        if (intervalo) {
            clearInterval(intervalo);
        }
        intervalo = setInterval(() => {
            sumarSegundo();
        }, 1000);
        return () => {
            clearInterval(intervalo);
        };
    }, [tiempo]);

    return (
        <div className="tiempo">
            <div className="hora">{visual.anio > 0 && obtenerHora(visual)}</div>
            <div className="fecha">
                {visual.anio > 0 && obtenerLiteral(visual)}
            </div>
            {error && <div> {error} </div>}
            <div className="enlace">
                <a href="https://ibmetro.gob.bo">www.ibmetro.gob.bo</a>
            </div>
        </div>
    );
}
