import logos from './media/imagen/logos.png';
import './App.css';
import HoraOficial from './pagina/tiempo/HoraOficial'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Container fluid>
          <Row>
            <Col className="titulo">
              HORA OFICIAL DEL ESTADO PLURINACIONAL DE BOLIVIA
            </Col>
          </Row>
          <Row>
            <Col className="contenido">
              <HoraOficial ></HoraOficial>
            </Col>
          </Row>
          <Row className="pie">
            <Col className="nombre" md={8} xs={12}>
              INSTITUTO BOLIVIANO DE METROLOGÍA
            </Col>
            <Col className="figuras" md={1} xs={0}>              
                
                  <div className="figuraA" />
                  <div className="figuraB" />
                  <div className="figuraC" />
                
            </Col>
            <Col className="logo" md={3} xs={12}>
              <img src={logos} />
              
            </Col>
          </Row>
        </Container>
      </header>

    </div>
  );
}

export default App;
