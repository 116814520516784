
import axios from 'axios'
import qs from 'query-string'
//import Config from '../config/Config'

const urlBase = window.location.protocol + '//horaoficial.ibmetro.gob.bo:' + (window.location.protocol.startsWith("https") ? '9191' : '9292') + '/v1'

/**
 * @param {string}  url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = '') =>
    url.startsWith('http://') || url.startsWith('https://') ? url : `${urlBase}/${url}`

const get = (url = '', headers = {}) => axios.get(readUrl(url), {
    headers: {
        Accept: ['application/json', 'text/plain'],
        'Content-Type': 'application/json',
        /* Authorization: `Bearer ${Config.auth.token}`, */
        ...headers
    }
})

const post = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), qs.stringify(body), {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        ...headers
    }
})

const put = (url = '', body = {}, headers = {}) => axios.put(readUrl(url), body, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
    }
})

const del = (url = '', headers = {}) => axios.delete(readUrl(url), {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers
    }
})

export default {
    get,
    post,
    put,
    delete: del,
}